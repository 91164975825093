<template>
    <div class="terms-of-cashback">
        <h1>How do I know the terms of cashback?</h1>
        <ol>
            <li>Through a support request</li>
            <li>Via an available communication channel from a web service specialis</li>
        </ol>
    </div>
</template>
<script>
export default {
    name:"howToKnowTermsOfCashBack",
    data() {
        return {
            locale: process.env.VUE_APP_LOCALE,
        }
    }
}
</script>
<style lang="scss" scoped>
.terms-of-cashback {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    
    h1 {
        line-height: normal;
    }
    ol,
    ul {
        list-style-position: inside;
    }
   
}
</style>